import React, { useState, useEffect } from "react";

import createPersistedState from "use-persisted-state";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import Table from "react-bootstrap/Table";
import Col from "react-bootstrap/Col";

import form from "../data/form.yaml";

function prettyDate(str) {
  let d = new Date(str.replace(" ", "T"));
  return d.toUTCString();
}

function insertPetName(label, petName) {
  let name = petName || "your pet";
  return label.replace(/<<petName>>/g, name);
}

const useAuth = createPersistedState("auth");

const PASSWORD = "eyesrgreat";

function Selected({ item, setSelected }) {
  return (
    <>
      <Button onClick={() => setSelected(null)}>&lt; Back</Button>
      <hr />
      <h3># / Date submitted:</h3>
      <h6>ID:</h6>
      <p>{item.id}</p>
      <h6>Date:</h6>
      <p>{prettyDate(item.created_at)}</p>
      {form.map((page) => {
        if (!page.inputs) return;

        let fields = [];
        for (let input of page.inputs) {
          if (input.type === "tableradio") {
            for (let tableInput of input.inputs) {
              fields.push({ label: tableInput.label, name: tableInput.name });
            }
          } else if (input.type === "upload") {
          } else if (input.type === "submit") {
          } else {
            fields.push({ label: input.label, name: input.name });
          }
        }

        let pageFieldNames = fields.map((field) => field.name);
        let dataFieldNames = Object.keys(item);
        let intersection = pageFieldNames.filter((name) =>
          dataFieldNames.includes(name)
        );

        if (!intersection.length) return;

        return (
          <>
            <h3>{page.title}</h3>
            {page.inputs.map((input) => {
              if (input.type === "tableradio") {
                return (
                  <>
                    <h6>{insertPetName(input.label, item.petName)}</h6>
                    <table
                      class="table table-striped table-bordered table-hover"
                      style={{ textLayout: "fixed" }}
                    >
                      <thead>
                        <tr>
                          <th></th>
                          {input.options.map((option) => (
                            <th
                              style={{
                                width: "14%",
                                textAlign: "center",
                                fontSize: "0.7em",
                                paddingLeft: 0,
                                paddingRight: 0,
                                verticalAlign: "middle",
                              }}
                              key={option.text}
                            >
                              {option.text}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {input.inputs?.map((nestedInput) => (
                          <tr>
                            <td>
                              {insertPetName(nestedInput.label, item.petName)}
                            </td>
                            {input.options.map((option) => (
                              <td
                                style={{ textAlign: "center" }}
                                key={option.value}
                              >
                                {option.value == item[nestedInput.name] && (
                                  <Form.Check
                                    type="radio"
                                    name={nestedInput.name}
                                    value={option.value}
                                    checked={true}
                                  />
                                )}
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </>
                );
              }

              if (!item[input.name]) return;

              if (input.type === "select") {
                let val = input.options?.find(
                  (option) => option.value === item[input.name]
                )?.text;

                return (
                  <>
                    <h6>{insertPetName(input.label, item.petName)}</h6>
                    <p>{val || item[input.name]}</p>
                  </>
                );
              }

              return (
                <>
                  <h6>{insertPetName(input.label, item.petName)}</h6>
                  <p>{item[input.name]}</p>
                </>
              );
            })}
          </>
        );
      })}
      <h3>Files:</h3>
      {(!item.files || !item.files.length) && <i>No files...</i>}
      {item.files?.map((file) => {
        if (typeof file === "string") file = { filePath: file };
        return file?.filePath && (
          <p>
            <a href={file.filePath} target="_blank" rel="noopener noreferrer">
              ...{file.filePath.substr(-8)}
            </a>
          </p>
        );
      })}
    </>
  );
}

export default function Admin() {
  let [pass, setPass] = useState("");
  let [auth, setAuth] = useAuth("");
  let [data, setData] = useState(null);
  let [dataStatus, setDataStatus] = useState("loading");
  let [dept, setDept] = useState("all");
  let [search, setSearch] = useState("");
  let [selected, setSelected] = useState(null);

  function login(e) {
    e.preventDefault();

    setAuth("loading");
    setTimeout(() => {
      if (pass === PASSWORD) setAuth("loginSuccess");
      else setAuth("loginError");
    }, 300);
  }

  function logout() {
    setAuth("");
  }

  useEffect(() => {
    setDataStatus("loading");

    fetch("/submission_data")
      .then((res) => res.json())
      .then((data) => {
        setDataStatus("loaded");
        setData(data);
      })
      .catch((err) => setDataStatus("error"));
  }, [auth]);

  return (
    <>
      {auth === "loginSuccess" ? (
        <>
          {dataStatus === "loading" && (
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          )}
          {dataStatus === "error" && (
            <Alert variant="danger">Unable to fetch data at this time.</Alert>
          )}
          {dataStatus === "loaded" && (
            <>
              <Form>
                <Form.Row>
                  <Col sm>
                    <Form.Group>
                      <Form.Label>Dept:</Form.Label>
                      <Form.Control
                        as="select"
                        value={dept}
                        onChange={(e) => setDept(e.target.value)}
                      >
                        <option value="all">All depts...</option>
                        {form[0].inputs[0].options.map((option) => (
                          <option value={option.value}>{option.text}</option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col sm>
                    <Form.Group>
                      <Form.Label>Search name:</Form.Label>
                      <Form.Control
                        type="text"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Form.Row>
              </Form>
              {selected ? (
                <Selected item={selected} setSelected={setSelected} />
              ) : (
                <Table striped bordered hover responsive>
                  <thead>
                    <tr>
                      <th># / Date</th>
                      <th>Dept / ConsultType:</th>
                      <th>Name: / Signalment:</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {data &&
                      data
                        .filter((item) => {
                          if (dept !== "all" && dept !== item.dept)
                            return false;

                          let regex = new RegExp(search, "i");
                          if (
                            search &&
                            !(item.petName + " " + item.lastName).match(regex)
                          )
                            return false;
                          return true;
                        })
                        .map((item) => (
                          <tr key={item.id}>
                            <td>
                              #{item.id} / {prettyDate(item.created_at)}
                            </td>
                            <td>
                              {item.dept} / {item.consultType}
                            </td>
                            <td>
                              <b>
                                "{item.petName}" {item.lastName}
                              </b>
                              <br />
                              {item.species} / {item.breed} / {item.sex} /{" "}
                              {item.neuteredStatus}
                            </td>
                            <td>
                              <Button onClick={() => setSelected(item)}>
                                View
                              </Button>
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </Table>
              )}
            </>
          )}
          <hr />
          <Button onClick={logout}>Logout</Button>
        </>
      ) : (
        <>
          {auth === "loginError" && (
            <Alert variant="danger">
              Incorrect login details, please try again.
            </Alert>
          )}
          <Form onSubmit={login}>
            <Form.Group>
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                onChange={(e) => setPass(e.target.value)}
                value={pass}
              />
            </Form.Group>
            <Button type="submit" disabled={auth === "loading"}>
              Login
            </Button>{" "}
            {auth === "loading" && (
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            )}
          </Form>
        </>
      )}
    </>
  );
}
