import "bootstrap/dist/css/bootstrap.min.css";
import "../styles.css";

import React, { useState } from "react";

import { Routes, Route, useRoutes } from "react-router-dom";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Admin from "./Admin.js";
import ScrollToTop from "./ScrollToTop.js";
import IntroPage from "./IntroPage.js";
import NotFound from "./NotFound.js";
import FormPage from "./FormPage.js";
import GuidePage from "./GuidePage.js";

import useData from "../utils/useData.js";

import form from "../data/form.yaml";
import guideOphth from "../data/guide_ophth.yaml";
import guideDerm from "../data/guide_derm.yaml";

export default function App() {
  let [data] = useData();
  let RouteMap = useRoutes([
    { path: "/", element: <IntroPage /> },
    ...form.map((page) => ({
      path: page.path,
      element: <FormPage page={page} pages={form} />,
    })),
    ...guideOphth.map((page) => ({
      path: page.path,
      element: <GuidePage page={page} pages={guideOphth} />,
    })),
    ...guideDerm.map((page) => ({
      path: page.path,
      element: <GuidePage page={page} pages={guideDerm} />,
    })),
    { path: "/admin", element: <Admin /> },
    { path: "*", element: <NotFound /> },
  ]);

  return (
    <div className="App">
      <ScrollToTop />
      <Container
        style={{
          background: "#fff",
          padding: "2em",
          borderRadius: "10px",
          boxShadow: "0 5px 5px #ddd",
          border: "1px solid #eee",
        }}
      >
        <Row className="justify-content-md-center">
          <Col xs={12} sm={12} md={10} lg={8} xl={8}>
            {RouteMap}
            {window.__DEBUG && (
              <pre style={{ border: "1px dashed black" }}>
                {JSON.stringify(data, null, "\t")}
              </pre>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
}
