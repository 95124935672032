import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import TextareaAutosize from "react-textarea-autosize";

import "filepond/dist/filepond.min.css";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

import useData from "../utils/useData.js";

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize,
  FilePondPluginFileValidateType
);

function insertPetName(label, petName) {
  let name = petName || "your pet";
  return label.replace(/<<petName>>/g, name);
}

export default function FormInputs({ inputs }) {
  let [data, setData, handle] = useData();

  if (!inputs) return;

  return inputs.map((input) => {
    let { label, name, options, inputs, required } = input;
    label = insertPetName(label, data.petName);
    switch (input.type) {
      case "text":
        return (
          <Form.Group key={name}>
            <Form.Label>{label}</Form.Label>
            <Form.Control
              type="text"
              value={data[name] ?? ""}
              onChange={handle(name)}
              required={required}
            />
            {required && (
              <Form.Control.Feedback type="invalid">
                This field is required.
              </Form.Control.Feedback>
            )}
          </Form.Group>
        );
      case "select":
        return (
          <Form.Group key={name}>
            <Form.Label>{label}</Form.Label>
            <Form.Control
              as="select"
              value={data[name] ?? ""}
              onChange={handle(name)}
              required={required}
            >
              <option value="">Choose...</option>
              {options &&
                options.map((option) => (
                  <option value={option.value} key={option.text}>
                    {option.text}
                  </option>
                ))}
            </Form.Control>
            {required && (
              <Form.Control.Feedback type="invalid">
                This field is required.
              </Form.Control.Feedback>
            )}
          </Form.Group>
        );
      case "tableradio":
        return (
          <Form.Group key={name}>
            <Form.Label>{label}</Form.Label>

            <table
              class="table table-striped table-bordered table-hover"
              style={{ textLayout: "fixed" }}
            >
              <thead>
                <tr>
                  <th></th>
                  {options.map((option) => (
                    <th
                      style={{
                        width: "14%",
                        textAlign: "center",
                        fontSize: "0.7em",
                        paddingLeft: 0,
                        paddingRight: 0,
                        verticalAlign: "middle",
                      }}
                      key={option.text}
                    >
                      {option.text}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {inputs.map((input) => (
                  <tr>
                    <td>{insertPetName(input.label, data.petName)}</td>
                    {options.map((option) => (
                      <td style={{ textAlign: "center" }} key={option.value}>
                        <Form.Check
                          type="radio"
                          name={input.name}
                          value={option.value}
                          checked={data[input.name] === option.value}
                          onChange={handle(input.name)}
                        />
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </Form.Group>
        );
      case "upload":
        return <UploadInput />;
      case "submit":
        return <Submit />;
      default:
        return (
          <Form.Group key={name}>
            <Form.Label>{label}</Form.Label>
            <TextareaAutosize
              className="form-control"
              minRows={3}
              value={data[name] ?? ""}
              onChange={handle(name)}
            />
          </Form.Group>
        );
    }
  });
}

function makeSerializable(files) {
  console.log(files);
  window.FILES = files;
  return files.map((file) => ({
    id: file.id,
    serverId: file.serverId,
    fileName: file.filename,
    fileSize: file.fileSize,
    fileType: file.fileType,
    status: file.status,
  }));
}

function UploadInput() {
  let [files, setFiles] = useState([]);
  let [data, setData] = useData();
  let pond = useRef();

  return (
    <FilePond
      key="upload"
      ref={pond}
      files={files}
      allowMultiple={true}
      maxFiles={10}
      acceptedFileTypes={["image/*", "video/*"]}
      maxFileSize={"200MB"}
      name={"file[]"}
      server={{
        url: "/filepond/api",
        process: "/process",
        revert: "/process",
      }}
      onupdatefiles={(files) => {
        pond.current.__files = files;
        setFiles(files);

        setData({ ...data, files: makeSerializable(files) });
      }}
      onprocessfile={(error, file) => {
        let files = pond.current.__files;

        setData({ ...data, files: makeSerializable(files) });
      }}
    />
  );
}

function sleep(duration) {
  return new Promise((resolve) => {
    setTimeout(resolve, duration);
  });
}

export function Submit() {
  let [data, setData] = useData();
  let [status, setStatus] = useState("");
  let navigate = useNavigate();

  function reset() {
    let answer = window.confirm(
      "Are you sure you want to reset the form? All current progress will be lost"
    );
    if (answer) {
      setData({ empty: true });
      setTimeout(() => {
        navigate("/");
      }, 10);
    }
  }

  function submit() {
    setStatus("loading");
    (async () => {
      try {
        await sleep(150);
        let resp = await fetch("/submit-form", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });

        const content = await resp.json();

        if (content.success) {
          setStatus("done");
          setData({ empty: true });
        } else {
          setStatus("error");
        }
      } catch (err) {
        setStatus("error");
      }
    })();
  }

  return (
    <>
      {status === "" && (
        <>
          <p>Ready to submit your details??</p>
          <Button onClick={submit} size="lg">
            Submit Form
          </Button>
        </>
      )}
      {status === "loading" && (
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      )}
      {status === "done" && (
        <Alert variant="success">
          Form submitted successfully. Thanks for filling out the pre-consult
          form!
        </Alert>
      )}
      {status === "error" && (
        <Alert variant="danger">
          An error occurred and has been logged. Please try again later.
        </Alert>
      )}
    </>
  );
}
