import React from "react";
import { useNavigate } from "react-router-dom";

import Figure from "react-bootstrap/Figure";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Button from "react-bootstrap/Button";

export default function Content({ content }) {
  let navigate = useNavigate();

  if (!content) return;

  if (!Array.isArray(content)) content = [content];

  return content.map((item) => {
    switch (item.type) {
      case "figure":
        return (
          <Figure
            key={item.key}
            style={{
              maxWidth: item.height / item.width < 0.66 ? "80%" : "60%",
              display: "block",
              margin: "1em auto",
            }}
          >
            <Figure.Image
              width={item.width}
              height={item.height}
              alt={item.alt}
              src={item.src}
            />
            <Figure.Caption>{item.caption}</Figure.Caption>
          </Figure>
        );
      case "ol":
        return (
          <ol>
            {item.items.map((listItem) => (
              <li>{listItem}</li>
            ))}
          </ol>
        );
      case "ul":
        return (
          <ul>
            {item.items.map((listItem) => (
              <li>{listItem}</li>
            ))}
          </ul>
        );
      case "card":
        return (
          <div
            style={{
              background: "#f3f3f6",
              textAlign: "center",
              borderRadius: "1em",
              padding: "1em",
              margin: "1em",
              border: "1px solid #ddd"
            }}
          >
            <Content content={item.content} />
          </div>
        );
      case "link":
        return (
          <ButtonToolbar style={{ justifyContent: "center" }}>
            <Button onClick={() => navigate(item.link)}>{item.text}</Button>
          </ButtonToolbar>
        );
      default:
        return <p>{item}</p>;
    }
  });
}
