export default function getActivePages(pages, data) {
  return pages.filter((page) => {
    if (page.match) {
      for (let [key, val] of Object.entries(page.match)) {
        if (Array.isArray(val) && !val.includes(data[key])) {
          return false;
        } else if (data[key] !== val) {
          return false;
        }
      }
      return true;
    }
    if (page.exclude) {
      for (let [key, val] of Object.entries(page.exclude)) {
        if (Array.isArray(val) && val.includes(data[key])) {
          return false;
        } else if (data[key] === val) {
          return false;
        }
      }
    }
    return true;
  });
}
