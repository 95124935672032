import createPersistedState from "use-persisted-state";

const _useData = createPersistedState("formData");

export let defaultData = { empty: true };

function useData() {
  let [data, setData] = _useData(defaultData);

  function handle(key) {
    return function (e) {
      let val = e.target.value === "" ? undefined : e.target.value;

      setData({
        ...data,
        [key]: val,
        empty: false,
      });
    };
  }

  return [data, setData, handle];
}

export default useData;
