import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Button from "react-bootstrap/Button";

export default function NotFound() {
  let location = useLocation();
  let navigate = useNavigate();

  return (
    <div>
      <h3 style={{ textAlign: "center" }}>404.</h3>
      <h5 style={{ textAlign: "center" }}>
        Page not found at: <code>{location.pathname}</code>
      </h5>
      <hr />
      <ButtonToolbar style={{ justifyContent: "center" }}>
        <Button className="mr-2" onClick={() => navigate("/")}>
          Back to Home
        </Button>
      </ButtonToolbar>
    </div>
  );
}
