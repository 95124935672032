export default function getPagesInfo(activePages, path) {
  let pageCount = activePages.length;
  let paths = activePages.map((page) => page.path);
  let currIdx = paths.indexOf(path);
  let currPage = activePages[currIdx];
  let currPageNumber = currIdx + 1;
  // let nextPath = currIdx === pageCount - 1 ? activePages[currIdx + 1] : null;
  let nextPath = activePages[currIdx + 1]?.path;
  // let prevPath = currIdx >= 1 ? activePages[currIdx - 1] : null;
  let prevPath = activePages[currIdx - 1]?.path;

  return {
    pageCount,
    paths,
    currIdx,
    currPage,
    currPageNumber,
    nextPath,
    prevPath,
  };
}
