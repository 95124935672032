import React from 'react';

import useData from "../utils/useData.js";
import { useNavigate } from "react-router-dom";

import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Button from "react-bootstrap/Button";

export default function IntroPage() {
  let [data, setData] = useData();
  let navigate = useNavigate();

  function cont() {
    navigate("/consult-type");
  }

  function startOver() {
    let answer = window.confirm(
      "Are you sure you want to reset the form? All current progress will be lost"
    );
    if (answer) {
      setData({ empty: true });
      setTimeout(() => {
        navigate("/consult-type");
      }, 10);
    }
  }

  return (
    <>
      <h1 style={{ textAlign: "center" }}>
        <img
          src="./logo_rowereferrals.svg"
          alt="Rowe Referrals"
          style={{ maxHeight: "150px", maxWidth: "90%" }}
        />
      </h1>
      <h3 style={{ textAlign: "center" }}>Telemedicine Pre-Consult Form</h3>
      <p style={{ textAlign: "center" }}>
        This web form helps give your veterinary specialist the best possible
        information to guide their care for you pet.
      </p>
      <p style={{ textAlign: "center" }}>
        Progress is saved as you enter your details, so you can come back and
        amend as necessary before submitting the form before your consultation.
      </p>
      <ButtonToolbar style={{ justifyContent: "center" }}>
        <Button className="mr-2" onClick={cont}>
          Continue
        </Button>
        {!data.empty && (
          <Button onClick={startOver} variant="secondary">
            Start over
          </Button>
        )}
      </ButtonToolbar>
    </>
  );
}
