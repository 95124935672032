import React, { useState, useRef } from "react";

import { useNavigate } from "react-router-dom";

import Button from "react-bootstrap/Button";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Badge from "react-bootstrap/Badge";
import Form from "react-bootstrap/Form";

import Content from "./Content.js";
import FormInputs from "./FormInputs.js";

import useData from "../utils/useData.js";
import getActivePages from "../utils/getActivePages.js";
import getPagesInfo from "../utils/getPagesInfo.js";

export default function FormPage({ page, pages }) {
  let [data, setData] = useData();
  let form = useRef();
  let [fieldsValidated, setFieldsValidated] = useState(false);
  let navigate = useNavigate();

  let { path, title, inputs, content, type } = page;

  let activePages = getActivePages(pages, data);
  let { pageCount, currPageNumber, nextPath, prevPath } = getPagesInfo(
    activePages,
    path
  );

  prevPath = prevPath || "/";

  let uploadsPending = 0;
  if (data.files) {
    for (let file of data.files) {
      if (file.status !== 5 && file.status !== 6) uploadsPending++;
    }
  }

  function nav(navPath) {
    if (path.match(/upload/) && uploadsPending) {
      return alert("Please wait for uploads to complete before leaving page");
    }
    if (
      navPath === nextPath &&
      form.current &&
      form.current.checkValidity() === false
    ) {
      return setFieldsValidated(true);
    }
    setFieldsValidated(false);
    navigate(navPath);
  }

  function reset() {
    let answer = window.confirm(
      "Are you sure you want to reset the form? All current progress will be lost"
    );
    if (answer) {
      setData({ empty: true });
      setTimeout(() => {
        navigate("/");
      }, 10);
    }
  }

  return (
    <>
      <h4 style={{ float: "right" }}>
        <Badge pill variant="primary">
          {currPageNumber} of {pageCount}
        </Badge>
      </h4>
      <h3>{title}</h3>
      {content && <Content content={content} />}
      {inputs && (
        <Form ref={form} validated={fieldsValidated}>
          <FormInputs inputs={inputs} />
        </Form>
      )}
      <hr />
      <ButtonToolbar className="justify-content-between">
        {prevPath && <Button onClick={() => nav(prevPath)}>&lt;- Prev</Button>}
        {nextPath ? (
          <Button onClick={() => nav(nextPath)}>Next -&gt;</Button>
        ) : (
          <Button onClick={reset} variant="secondary">
            Reset Form
          </Button>
        )}
      </ButtonToolbar>
    </>
  );
}
