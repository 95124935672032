import React from "react";
import querystring from "query-string";

import { useNavigate, useLocation } from "react-router-dom";

import Button from "react-bootstrap/Button";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Content from "./Content.js";

import getPagesInfo from "../utils/getPagesInfo.js";

export default function GuidePage({ page, pages }) {
  let location = useLocation();
  let navigate = useNavigate();
  let { path, title, content } = page;
  let { from, onfinish } = querystring.parse(location.search);

  let { nextPath, prevPath } = getPagesInfo(pages, path);

  prevPath = prevPath || from;

  return (
    <>
      <h1 style={{ textAlign: "center" }}>
        <img
          src="/logo_rowereferrals.svg"
          alt="Rowe Referrals"
          style={{ maxHeight: "70px", maxWidth: "90%" }}
        />
      </h1>
      <hr />
      <h3>{title}</h3>
      {content && <Content content={content} />}
      <hr />
      <ButtonToolbar className="justify-content-between">
        {prevPath && (
          <Button onClick={() => navigate(prevPath + location.search)}>
            &lt;- Prev
          </Button>
        )}
        {nextPath && (
          <Button onClick={() => navigate(nextPath + location.search)}>
            Next -&gt;
          </Button>
        )}
        {!nextPath && onfinish && (
          <Button onClick={() => navigate(onfinish)}>
            Continue Form -&gt;
          </Button>
        )}
      </ButtonToolbar>
    </>
  );
}
